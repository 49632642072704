import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import HeroBg from "../components/heroBg"
import bgImage from "../images/backgrounds/hero-website-about-cliqit.png"
import ContactFormSection from "../components/contactFormSection"
import { Link } from "gatsby"

const AboutPage = ({ location }) => (
  
<>
  {/* Hero section  */}
  <Layout
    breadcrumb={
      {"Über cliqit": "/ueber-cliqit"}
    }
  >
    <Seo 
      title="Deine Agentur für Online Werbung & Websites | cliqit"
      description="Hier erfährst du die Entstehungsgeschickte von cliqit. Wie aus Hobby eine Leidenschaft wurde."
      lang="de"
      pathname={location.pathname}
      robots="index,follow"
    />
    <Section noSeparator>
      <div className="row">
        <div className="col-md-8">
          <h1 className="small">So kam es zu cliqit</h1>
          <h2 className="main-heading">Aus Hobby wurde Leidenschaft</h2>
        </div>
        <div className="col-md-6">
          <p>Servus, ich bin Christian, das Gesicht hinter cliqit. 2013 bin ich eher zufällig in das Online Marketing hineingeplumst und habe mich
            sofort wohl geführt.<br/><br/>
            Seither konnte ich meine Leidenschaft stetig vertiefen. Neben meinem dualen Studium "Digitale Medien - Medienmanagement & Kommunikation"
            konnten ich bei einem großen Medienhaus unteranderem <strong>mehrjährige Erfarungen</strong> in den Marketingfeldern <strong><Link to="/online-marketing#plattformen">Suchmaschinenmarketing (SEA & SEO)</Link> 
            und Affiliate Marketing</strong> sammeln. Durch die  Verantwortung neuer Tracking- und Website-Projekte habe ich zeitgleich mein <strong>technisches Know-How </strong>
            gestärkt.
            <br/><br/>
            Mein weiterer Weg hat mir die Möglichkeit gegeben, unter anderem auch <strong>international im Marketing zu arbeiten</strong>, bevor ich wieder im schönen Ortenaukreis
            gelandet bin, <strong>wo ich heute bei einem schnellwachsenden Startup im Produkt & Performance Marketing arbeite</strong>.
            <br/><br/>
            <strong>Was für viele so technisch und komplex klingt, muss nicht unbedingt kompliziert sein.</strong> Auch wenn die Online Welt von vielen
            Buzz-Words geprägt ist, habe ich bei diversen privaten Projekten für Freunde und Familie gelernt, dass dies im Umgang mit Kunden nicht nötig ist.
            Daher will ich mit cliqit helfen, dein Business nach deinen Bedürfnissen in die digitale Welt zu bringen. Sei es mit einer neuen <Link to="/website-und-seo">Website</Link> und  
            <Link to="/online-marketing"> Online Marketingmaßnahmen</Link> oder beim Klären deiner Fragen zu <Link to="/webtracking">Webanalytics und Tracking</Link>.
            </p>
        </div>
      </div>
    </Section>
    <HeroBg 
      bgImage={bgImage}
    />

    <ContactFormSection 
      heading="Neugierde geweckt?"
      text="Dann lass uns in Kontakt treten und dein Business mit Leidenschaft weiterentwickeln."
    />

    
  </Layout>
</>
)
export default AboutPage
